import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import H1 from '../components/H1'

export default function produktyPszczele ({ data, location }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { title } = frontmatter
  return (
    <Layout>
      <H1>{ title }</H1>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </Layout>
  )
}
  
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`